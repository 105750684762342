import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-site',
  templateUrl: './full-site.component.html',
  styleUrls: ['./full-site.component.scss']
})
export class FullSiteComponent implements OnInit {
  site = {
    name: 'SOLUCIONES_NT',
    folder: 'solucionesIT',
    colors: {
      primary: '#007db8',
      secondary: 'white'
    }
  };
  imgPath = `assets/img/pages/${this.site.folder}`;
  fullPage;
  constructor() {
    this.fullPage = `${this.imgPath}/fullpage.png`;
   }

  ngOnInit() {
  }

}
