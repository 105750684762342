export const webPlans = [{
    name: 'Wb Starter',
    items: [
        'PLANS.DOMAIN',
        'PLANS.HOSTING',
        'PLANS.SEO',
        'PLANS.SSL',
        'PLANS.DEV',
        'PLANS.MAILING',
        'PLANS.IMG',
        'PLANS.HOME',
        'PLANS.US',
        'PLANS.SOCIAL',
        'PLANS.WP'
    ]
},{
    name: 'Wb Plus',
    main: true,
    items: [
        'PLANS.DOMAIN',
        'PLANS.HOSTING',
        'PLANS.SEO',
        'PLANS.SSL',
        'PLANS.DEV',
        'PLANS.MAILING',
        'PLANS.IMG',
        'PLANS.HOME',
        'PLANS.US',
        'PLANS.SOCIAL',
        'PLANS.WP',
        'PLANS.SERVICES',
        'PLANS.CART',
        'PLANS.PAY',
        'PLANS.SELF'
    ]
},{
    name: 'Wb Custom',
    items: [
        'PLANS.MOBILE',
        'PLANS.WEB_APP',
        'PLANS.CUSTOM'
    ]
}];


export const designPlans = [{
    name: 'Wb Design',
    items: [
        'PLANS.DESIGN_LOGOTIPO',
        'PLANS.DESIGN_COLORS',
        'PLANS.DESIGN_TIPO',
        'PLANS.DESIGN_ADDS',
        'PLANS.DESIGN_IMG'
    ]
},{
    name: 'Wb Marketing',
    main: true,
    items: [
        'PLANS.MARKETING_MAILING',
        'PLANS.MARKETING_SEM',
        'PLANS.MARKETING_SOCIAL',
        'PLANS.MARKETING_SOCIAL_MEDIA'
    ]
}];

export const maintenance = [
    'MAIN_SOPORTE',
    'MAIN_EMAIL',
    'MAIN_DOMAIN',
    'MAIN_MODIFY',
    'MAIN_SECURITY'
]