import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  site = {
    name: 'SOLUCIONES_NT',
    folder: 'solucionesIT',
    colors: {
      primary: '#007db8',
      secondary: 'white'
    }
  };
  team;
  duration;
  deliverables;
  products;
  technologies;
  path = `PROJECTS.${this.site.name}.SUMMARY`;
  constructor() {
    this.team = `${this.path}.TEAM`;
    this.duration = `${this.path}.DURATION`;
    this.deliverables = `${this.path}.DELIVERABLES`;
    this.products = `${this.path}.PRODUCTS`;
    this.technologies = `${this.path}.TECHNOLOGIES`;
  }

  ngOnInit() {
  }

}
