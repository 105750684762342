import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsive',
  templateUrl: './responsive.component.html',
  styleUrls: ['./responsive.component.scss']
})
export class ResponsiveComponent implements OnInit {
  site = {
    name: 'SOLUCIONES_NT',
    folder: 'solucionesIT',
    colors: {
      primary: '#007db8',
      secondary: 'white'
    }
  };
  img;
  description = `PROJECTS.${this.site.name}.RESPONSIVE.DESCRIPTION`;
  constructor() {
    this.img = `assets/img/pages/${this.site.folder}/responsive.png`;
   }

  ngOnInit() {
  }

}
