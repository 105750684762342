import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import Typed, { TypedOptions } from 'typed.js';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  typed1;
  typed2;
  @ViewChild("sidebar", {static: true}) sidebar: ElementRef;
  @ViewChild("navButton", {static: true}) navButton: ElementRef;
  constructor(private translateService: TranslateService, private renderer: Renderer2) { }

  ngOnInit() {
    this.typed();
  }

  typed(){
    if(this.typed1) this.typed1.destroy();
    if(this.typed2) this.typed2.destroy();
    this.translateService.get('HEADER_WB.TYPED').subscribe(typedArray => {
      const options: TypedOptions = {
        strings: typedArray,
        typeSpeed: 40,
        backDelay: 700,
        loop: true,
        loopCount: 0
      };
      this.typed1  = new Typed('#typed', options);
      this.typed2 = new Typed('#typed2', options);
    });
  }

  changeLang(lang) {
    this.translateService.use(lang);
    this.typed();
  }

  hide(){
    this.renderer.removeClass(this.sidebar.nativeElement, 'visibleSidebar');
    this.renderer.removeClass(this.navButton.nativeElement, 'active');
  }
}
