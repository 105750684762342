import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from '../../../services/projects.service';
@Component({
  selector: 'app-header-web-right',
  templateUrl: './header-web-right.component.html',
  styleUrls: ['./header-web-right.component.scss']
})
export class HeaderBackgroundImageComponent implements OnInit {
  site;
  company;
  title;
  subtitle;
  img;
  challenge;
  path;

  constructor(private translateService: TranslateService, private projectService: ProjectsService) {
    this.site = this.projectService.siteInformation;
    if (this.site) {
      this.path = `PROJECTS.${this.site.name}.HEADER`;
      this.company = `${this.path}.COMPANY`;
      this.title = `${this.path}.TITLE`;
      this.subtitle = `${this.path}.SUBTITLE`;
      this.img = `assets/img/pages/${this.site.folder}/header.png`;
      this.challenge = `${this.path}.CHALLENGE`;
    }
  }

  ngOnInit() {
  }

  changeLang(lang) {
    this.translateService.use(lang);
  }

}
