export const config = {
    projects: [{
        name: 'CENTRAL_MAC',
        subtitle: 'E_COMMERCE',
        text: 'E_COMMERCE',
        folder: 'central_mac',
        link: 'https://www.centralmac.com.ar/',
        tags: ['WEB', 'RESPONSIVE', 'E_COMMERCE'],
        tech: ['ANGULAR'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },{
        name: 'AUTOS_DEL_SUR',
        subtitle: 'LANDING_PAGE',
        text: 'AUTOS_DEL_SUR_TEXT',
        folder: 'autos_del_sur',
        link: 'http://autosdelsurford.com',
        tags: ['WEB', 'RESPONSIVE'],
        tech: ['ANGULAR'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'NT_SOLUCIONES',
        subtitle: 'LANDING_PAGE',
        text: 'NT_SOLUCIONES_TEXT',
        folder: 'nt_soluciones',
        link: 'https://new-technology.com.ar/soluciones/',
        tags: ['WEB', 'RESPONSIVE', 'LANDING_PAGE'],
        tech: ['ANGULAR'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'NT_TIENDAS',
        subtitle: 'E_COMMERCE',
        text: 'NT_TIENDAS_TEXT',
        folder: 'nt_tiendas',
        link: 'https://new-technology.com.ar/tiendas/',
        tags: ['WEB', 'RESPONSIVE', 'E_COMMERCE'],
        tech: ['ANGULAR', 'AWS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'T_BIRD',
        subtitle: 'E_COMMERCE',
        text: 'T_BIRD_TEXT',
        folder: 't_bird',
        tags: ['WEB', 'RESPONSIVE', 'E_COMMERCE'],
        tech: ['PHP', 'HTML', 'CSS', 'JS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'SMART_Z',
        subtitle: 'IOT',
        text: 'SMART_Z_TEXT',
        folder: 'smart_z',
        link: 'https://clients.webbuilders.com.ar/smartz/',
        tags: ['IOT', 'ANDROID', 'IOS'],
        tech: ['ANGULAR', 'NODE', 'AWS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'DGF',
        subtitle: 'LANDING_PAGE',
        text: 'DGF_TEXT',
        folder: 'dgf',
        link: 'https://globalforwarding.com.ar',
        tags: ['WEB', 'RESPONSIVE'],
        tech: ['PHP', 'HTML', 'CSS', 'JS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'RG_GROUP',
        subtitle: 'LANDING_PAGE',
        text: 'RG_GROUP_TEXT',
        folder: 'rg_group',
        link: 'https://www.rggroupsrl.com.ar/',
        tags: ['WEB', 'RESPONSIVE'],
        tech: ['ANGULAR'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'NT_SERVICIO',
        subtitle: 'WEB_APPLICATION',
        text: 'NT_SERVICIO_TEXT',
        folder: 'nt_servicio',
        tags: ['WEB_APPLICATION'],
        tech: ['PHP', 'HTML', 'CSS', 'JS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'MEVOYABCN',
        subtitle: 'BLOG',
        text: 'MEVOYABCN_TEXT',
        folder: 'mevoyabcn',
        link: 'https://www.mevoyabcn.com/',
        tags: ['BLOG'],
        tech: ['PHP', 'HTML', 'CSS', 'JS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'PRODUCIR_INTEGRAL',
        subtitle: 'WEB_APPLICATION',
        text: 'PRODUCIR_INTEGRAL_TEXT',
        folder: 'producir_integral',
        link: 'https://www.producirintegral.com/es/',
        tags: ['WEB_APPLICATION'],
        tech: ['PHP', 'HTML', 'CSS', 'JS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'BARRANCAS',
        subtitle: 'WEB',
        text: 'BARRANCAS',
        folder: 'barrancas',
        tags: ['WEB'],
        tech: ['PHP', 'HTML', 'CSS', 'JS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'CREDION',
        subtitle: 'WEB_APPLICATION',
        text: 'CREDION_TEXT',
        folder: 'credion',
        tags: ['WEB_APPLICATION'],
        tech: ['PHP', 'HTML', 'CSS', 'JS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    },
    {
        name: 'PRISMA',
        subtitle: 'E_COMMERCE',
        text: 'PRISMA_TEXT',
        folder: 'prisma',
        tags: ['E_COMMERCE'],
        tech: ['PHP', 'HTML', 'CSS', 'JS'],
        work: ['UX', 'DESIGN', 'DEVELOPMENT']
    }]
};