import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { config } from 'src/app/config/config';

@Component({
  selector: 'app-modal-project',
  templateUrl: './modal-project.component.html',
  styleUrls: ['./modal-project.component.scss']
})
export class ModalProjectComponent implements OnInit {
  project;
  projects = config.projects;
  actual = 0;
  constructor(
    public dialogRef: MatDialogRef<ModalProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.project = this.data;
    this.actual = this.projects.findIndex((e) => e.name === this.project.name);
  }

  getLabel(name){
    return `WORK.${name}`;
  }

  open(){
    window.open(this.project.link, '_blank')
  }

  next(){
    if(this.actual === this.projects.length - 1) return;
    this.actual = this.actual + 1;
    this.project = this.projects[this.actual];
  }

  previous(){
    if(this.actual === 0) return;
    this.actual = this.actual - 1 ;
    this.project = this.projects[this.actual];
  }
}
