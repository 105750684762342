import { Component, OnInit, Input } from '@angular/core';
import { ProjectsService } from '../../services/projects.service';
import { Project } from '../../classes/project.class';

@Component({
  selector: 'app-next-banner',
  templateUrl: './next-banner.component.html',
  styleUrls: ['./next-banner.component.scss']
})
export class NextBannerComponent implements OnInit {

  project: Project;
  nextProject: Project;

  constructor(private _projectService: ProjectsService) { }

  ngOnInit() {
    this.project = this._projectService.siteInformation;
    //this.nextProject = this._projectService.getNextProject();
  }

  open(){
    window.open(this.project.link, '_blank');
  }

}
