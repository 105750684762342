import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-wp-button',
  templateUrl: './wp-button.component.html',
  styleUrls: ['./wp-button.component.scss']
})
export class WpButtonComponent implements OnInit {
  @Input() phone;
  constructor() { }

  ngOnInit() {
  }

  open(){
    window.open(`https://api.whatsapp.com/send?phone=${this.phone}`);
  }

}
