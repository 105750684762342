import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, AfterContentChecked, AfterViewChecked, ViewChildren, QueryList } from '@angular/core';
import { config } from '../../config/config';
import { MatDialog } from '@angular/material/dialog';
import { ModalProjectComponent } from '../modal-project/modal-project.component';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  @ViewChild('portfolio', {static: true}) portfolioView: ElementRef;
  fixed = false;
  projects: any[] = config.projects;
  index = 0;
  number = 6;
  height;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
  }


  getImg(proj){
      return `/assets/img/pages/${proj.folder}/main.png`;
  }

  getLabel(name){
    return `WORK.${name}`;
  }

  /**
   * Open modal
   * 
   * @param project Project
   */
  open(project){
    this.dialog.open(ModalProjectComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      autoFocus: false,
      data: project
    });
  }

  next(){
    if(! this.fixed){
      this.height = this.portfolioView.nativeElement.offsetHeight;
      this.fixed = true;
    }
    this.index = this.index + this.number;
  }

  previous(){
    this.index = this.index - this.number;
  }

  more(){
    this.number = this.number * 2;
  }

}
