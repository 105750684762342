import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
    title = 'wb';
    constructor(translate: TranslateService, private route: ActivatedRoute) {
      const lang = this.route.snapshot.paramMap.get('lang');
      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang('es');
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use('es');
  }
}
