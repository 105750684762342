import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-technology-label',
  templateUrl: './technology-label.component.html',
  styleUrls: ['./technology-label.component.scss']
})
export class TechnologyLabelComponent implements OnInit {
  @Input() tech;
  constructor() { }

  ngOnInit() {
  }

}
