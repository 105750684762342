import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-images',
  templateUrl: './grid-images.component.html',
  styleUrls: ['./grid-images.component.scss']
})
export class GridImagesComponent implements OnInit {
  site = {
    name: 'SOLUCIONES_NT',
    folder: 'solucionesIT',
    colors: {
      primary: '#007db8',
      secondary: 'white'
    }
  };
  imgPath = `assets/img/pages/${this.site.folder}`;
  firstColumn = [];
  constructor() {
    for (let i = 1; i <= 6; i++) {
      this.firstColumn.push(`${this.imgPath}/grid${i}.png`);
    }
   }

  ngOnInit() {
  }

}
