import { Component, OnInit } from '@angular/core';
import { maintenance } from '../../config/plans';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  plan = maintenance;
  constructor() { }

  ngOnInit() {
  }

}
