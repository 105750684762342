import { Injectable } from '@angular/core';
import { config } from '../config/config';
import { Project } from '../classes/project.class';
@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  siteInformation: Project;
  projectsArray;

  constructor() {
    this.projectsArray = Object.keys(config.projects);
  }

  set(name) {
    //this.siteInformation = new Project(config.projects[name]);
  }

  getNextProject(){
    return (this.siteInformation.id >= ( this.projectsArray.length - 1 )) ?
      config.projects[this.projectsArray[0]] :
      config.projects[this.projectsArray[this.siteInformation.id + 1]];
  }
}
