import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ServicesComponent } from './components/services/services.component';
import { HomeComponent } from './pages/home/home.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UsComponent } from './components/us/us.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { TeamComponent } from './components/team/team.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProjectComponent } from './pages/projects/projects.component';
import { HeaderBackgroundImageComponent } from './components/pages/header-web-right/header-web-right.component';
import { SummaryComponent } from './components/pages/summary/summary.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// material
import {MatButtonModule} from '@angular/material/button';
import { ResponsiveComponent } from './components/pages/responsive/responsive.component';
import { FullSiteComponent } from './components/pages/full-site/full-site.component';
import { GridImagesComponent } from './components/pages/grid-images/grid-images.component';
import { NextBannerComponent } from './components/next-banner/next-banner.component';
import { WpButtonComponent } from './components/wp-button/wp-button.component';
import {MatBadgeModule} from '@angular/material/badge';
import { ModalProjectComponent } from './components/modal-project/modal-project.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { TechnologyLabelComponent } from './components/technology-label/technology-label.component';
import { PlansComponent } from './components/plans/plans.component';
import {MatCardModule} from '@angular/material/card';
import { EmptyDividerComponent } from './components/empty-divider/empty-divider.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ServicesComponent,
    HomeComponent,
    UsComponent,
    PortfolioComponent,
    TeamComponent,
    ContactComponent,
    FooterComponent,
    ProjectComponent,
    HeaderBackgroundImageComponent,
    SummaryComponent,
    ResponsiveComponent,
    FullSiteComponent,
    GridImagesComponent,
    NextBannerComponent,
    WpButtonComponent,
    ModalProjectComponent,
    TechnologyLabelComponent,
    PlansComponent,
    EmptyDividerComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    MatButtonModule,
    MatBadgeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [ModalProjectComponent]
})
export class AppModule { }
